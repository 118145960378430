import React from "react";
import CharityAllocationV1 from "./charityAllocationV1";
import CharityAllocationV2 from "./charityAllocationV2";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";

const CharityAllocationPage = () => {
  return isZakat2025Enabled ? <CharityAllocationV2 /> : <CharityAllocationV1 />;
};

export default CharityAllocationPage;
